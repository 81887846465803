import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
/* import { toast } from 'react-toastify' */
import { Link } from 'react-router-dom'
import { useHistory, useRouteMatch } from 'react-router'
import classNames from 'classnames'

import { PartnersLogoGrid } from '../../components/Partners'
import { API_IMG } from '../../constants/Api.constants'
import Loader from '../../components/Loader'
import { formatArrayImages, getCoverImage } from '../utils/getObjectFields'
import { formatFilesData, formatVideoData, formatYoutubeLink } from '../utils/formatFilesData'
import MediaPopup from '../../components/Popup/MediaPopup'
import CommentPopup from '../../components/CommentPopup'
import ProjectInfo from '../../components/ProjectInfo'
import MetaTags from '../../components/MetaTags'
import ProjectMaterials from '../../components/ProjectMaterials'
import PartnersContainer from '../../components/PartnersContainer'
/* import CommentsContainer from '../../components/CommentsContainer' */
import ProjectProgress from '../../components/ProjectProgress'
import BaseLayout from '../../layouts/BaseLayout'
import EstimateTable from '../../components/EstimateTable'
import ProjectMembers from '../../components/ProjectInfo/ProjectMembers'
import ProjectFields from '../../components/ProjectInfo/ProjectFields'
import CardSlider from '../../components/CardSlider'
import PartnerPerson from '../../components/PartnerPerson'
import { formatName } from '../../helpers/formatters'
import SupportProject from '../../components/SupportProject'
import SharingButtons from '../../components/SharingButtons'
import ProjectBonuses from '../../components/ProjectInfo/ProjectBonuses'
import ProjectBonusesFromPartners from '../../components/ProjectInfo/ProjectBonusesFromPartners'
import ProjectPayment from '../../components/ProjectPayment/ProjectPayment'

/* import { DEFAULT_PER_PAGE } from './constants' */
import {
  getProjectAction,
  getProjectCommentsAction,
  clearProjectData,
  postProjectCommentAction
} from './Project.actions'

// TODO: Fix problem with big titles for small 320x568 devices

const ProjectPage = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const id = match.params.id
  /*  const [ commentsCount, setCommentsCount ] = useState(DEFAULT_PER_PAGE) */
  const [ isShowCommentPopup, setIsShowCommentPopup ] = useState(false)

  const { data, isFetching, /* isCommentsFetching, comments, */ isPaymentFormVisible, stageType, error } = useSelector(state => state.project)
  /*  const { user } = useSelector(state => state.auth) */
  const { isShowMobileMenu } = useSelector(state => state.baseLayoutReducer)

  const projectMembers = data && [ data.ambassador, data.quote ]

  const isDoneStage = data && data.stage === 'done'
  const isFinalStage = data && data.collected >= data.goal
  const isShowBottomContainer = !isPaymentFormVisible && !isShowMobileMenu && !isDoneStage && !isFinalStage

  const dispatch = useDispatch()

  const [ isFixedDonates, setIsFixedDonates ] = useState(false)

  useEffect(() => {
    const onScrollHandler = () => {
      if (typeof window !== 'undefined') {
        setIsFixedDonates(window.pageYOffset > 100)
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScrollHandler)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', onScrollHandler)
      }
    }
  }, [])

  const donatesClassName = classNames('project-donates', { 'project-donates--fixed-top': isFixedDonates })

  useEffect(() => {
    dispatch(getProjectAction(id))
    dispatch(getProjectCommentsAction(id))

    return () => {
      dispatch(clearProjectData())
    }
  }, [ id, dispatch ])

  const [ isShowFileModal, showFileModal ] = useState(false)

  const [ src, setSrc ] = useState(null)
  const [ videoLink, setVideoLink ] = useState(null)
  const [ videoDescription, setVideoDescription ] = useState(null)

  /*  useEffect(() => {
    commentsCount !== DEFAULT_PER_PAGE && dispatch(getProjectCommentsAction(id, commentsCount))
  }, [ id, dispatch, commentsCount ]) */
  console.log(error)
  if (error !== null) {
    history.push('/not-found')
  }

  if (isFetching || !data) {
    return (
      <BaseLayout>
        <div className="container-app">
          <div className="project__loader">
            <Loader />
          </div>
        </div>
      </BaseLayout>
    )
  }

  const videoMessage = data.video_message && formatYoutubeLink(data.video_message.link)

  /*  const onCommentAction = () => {
    if (user) {
      setIsShowCommentPopup(!isShowCommentPopup)
    } else {
      toast.error('Комментарии могут оставлять только авторизованные пользователи')
    }
  } */

  const { title, description, seo_description: seoDescription, seo_keywords: seoKeywords, seo_title: seoTitle } = data

  const metaImage = getCoverImage(data, 'logo', true)

  const handleMediaItemClick = (type, mediaItem) => {
    if (type === 'video') {
      setSrc(null)
      setVideoLink(mediaItem.link)
      showFileModal(!isShowFileModal)
      setVideoDescription(mediaItem.description)
    } else if (type === 'image') {
      setVideoLink(null)
      setVideoDescription(null)
      setSrc(mediaItem.src)
      showFileModal(!isShowFileModal)
    }
  }

  return (

    <React.Fragment>
      {isShowCommentPopup &&
        <CommentPopup
          onClose={setIsShowCommentPopup}
          onPost={postProjectCommentAction}
        />
      }

      {isShowFileModal &&
        <MediaPopup
          src={src}
          videoLink={videoLink}
          onClose={showFileModal}
          videoDescription={videoDescription}
          data={[ ...data.files, ...data.videos ]}
        />
      }

      <BaseLayout>
        <MetaTags
          type="article"
          title={seoTitle}
          description={seoDescription}
          keyWords={seoKeywords}
          ogTitle={seoTitle}
          ogDescription={seoDescription}
          ogImage={metaImage}
        />
        <div className="container-app">
          <div className="container-fluid">
            <div className={donatesClassName}>
              <div className="project-donates__container">
                <div className="col-md-12 col-lg-7">
                  <ProjectProgress
                    type="large"
                    partners={data.total_partners_count}
                    projectData={data}
                    stageType={stageType}
                  />
                </div>
                <ProjectPayment data={data} />
              </div>
            </div>
            <div className="project__container">
              <div className="col-md-12 col-lg-7">
                {(isDoneStage || isFinalStage) && <div className="project__support-advice">
                  Поддержите будущие проекты этого инициатора или <Link to={'/projects?type=all'} >проекты других инициаторов</Link>.
                </div>}

                <h2 className="project__title-block">{title}</h2>
                <div className="project__status-label">
                  <span>Одобрено экспертами</span>
                </div>
                <div className="project-cover">
                  <img
                    alt="Main cover of project"
                    className="project-cover__cover"
                    src={data.logo ? `${API_IMG}/${data.logo.filename}` : '/images/female-icons/no-userpic.svg'}
                  />
                </div>

                <div className="project__text-container">
                  <div className="project__text" dangerouslySetInnerHTML={{ __html: description }} />
                </div>

                <div className="project__elements-container">
                  <ProjectMembers projectMembers={projectMembers} />
                </div>

                {videoMessage &&
                  <div className="project__text-container">
                    <iframe
                      title="Video message"
                      className="project__video-message"
                      src={videoMessage}
                      allow="accelerometer; fullscreen; preview;"
                      width="100%" />

                  </div>
                }

                <ProjectMaterials
                  videos={formatVideoData(data.videos)}
                  files={formatFilesData(data.files)}
                  handleMediaItemClick={(type, item) => handleMediaItemClick(type, item)}
                />

                <div className="project__text-container">
                  <h4 className="project__sub-title">На что направлен проект</h4>
                  <div className="project__text" dangerouslySetInnerHTML={{ __html: data.info }} />
                </div>

                <div className="project__elements-container">
                  <ProjectBonusesFromPartners />
                  <ProjectBonuses />
                  <ProjectFields fields={data} />
                </div>

                {data.estimate &&
                  <EstimateTable
                    estimate={data.estimate}
                    isDoneStage={isDoneStage}
                  />
                }

              </div>
              <ProjectInfo data={data} />
            </div>

            <div className="project__slider-container">
              <PartnersContainer partners={data.donation_users || null} />
            </div>
            {/* TODO: Create single component for pages Project and School. */}
            <div className="project__slides-partners">
              <h3 className="project__title-block">Проект поддерживают</h3>

              {data.donation_users.length === 0 &&
                <span>Стань первым, кто поддержит проект.</span>
              }

              <CardSlider
                slidesToShow={data.donation_users.length === 1 ? 1 : 2}
                isShowLoader={isFetching}
                slidesToScroll={2}
              >
                {data.donation_users && data.donation_users.map((person, index) => (
                  <PartnerPerson
                    key={index}
                    portrait={person}
                    name={formatName(person.first_name, person.last_name)}
                    position={person.position}
                    company={person.company}
                  />
                ))}
              </CardSlider>
            </div>

            <PartnersLogoGrid
              className="margin-bottom-75"
              partners={formatArrayImages(data.vendors).map(item => ({ logo: item.logo, link: item.link }))}
            />

            <div className="project__sharing">
              <SharingButtons
                title={data.title}
                isDescriptionVisible
              />
              <div className="sharing-container__text">Поделиться проектом</div>
            </div>
            {/* TODO: Вернуть или удалить по необходимости */}
            {/*            <CommentsContainer
              comments={comments}
              onCommentAction={() => onCommentAction()}
              setCommentsCount={(count) => setCommentsCount(count)}
              commentsCount={commentsCount}
              isCommentsFetching={isCommentsFetching}
              user={user || null}
            /> */}
          </div>
        </div>
      </BaseLayout>
      {isShowBottomContainer && <SupportProject />}
    </React.Fragment>
  )
}

export default ProjectPage
