import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'

import { POST_COMMENTS_FIELDS, POST_COMMENTS_FIELDS_INITIAL, POST_COMMENTS_VALIDATION } from '../CommentPopup/contstants'
import { TextareaField } from '../Input'
import { Button } from '../Button'

const CommentPopup = ({ onClose, onPost, match: { params: { id } } }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  const onSubmit = (data) => {
    dispatch(onPost({ project: id, text: data.text }, () => onClose(false)))
  }

  const {
    project: { isCommentPosting }
  } = useSelector(state => state)

  return (
    <div className="comment-popup">
      <div className="comment-popup-content">
        <div className="comment-popup-content__close-icon" onClick={() => onClose(false)} />
        <h1>Оставьте комментарий</h1>
        <Formik
          initialValues={POST_COMMENTS_FIELDS_INITIAL}
          enableReinitialize={true}
          validationSchema={POST_COMMENTS_VALIDATION}
          onSubmit={(data) => onSubmit(data)}
        >
          {(f) => {
            return (
              <form onSubmit={f.handleSubmit}>
                <TextareaField
                  name={POST_COMMENTS_FIELDS.TEXT}
                  value={f.values.text}
                  error={f.errors.text && f.touched.text && f.errors.text}
                  onChange={event => f.setFieldValue('text', event.target.value)}
                  onBlur={f.handleBlur}
                />
                <Button
                  label={isCommentPosting ? 'Отправляется...' : 'Отправить'}
                  type="submit"
                  width={200}
                  height={56}
                  disabled={(!f.isValid || isCommentPosting)}
                  color={(!f.isValid) || (isCommentPosting) ? 'gray' : 'blue'}
                />
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default withRouter(CommentPopup)
