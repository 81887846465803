import React from 'react'
import { useDispatch } from 'react-redux'

import { onPaymentFormAction } from '../../pages/Project/Project.actions'
import ModalContainer from '../ModalContainer/ModalContainer'
import PaymentForm from '../PaymentForm/PaymentForm'
import SuccessForm from '../PaymentForm/stages/SuccessForm'

const PaymentFormContainer = ({
  isPaymentFormVisible,
  isSuccessPaymentModal,
  selectedProject,
  setIsSuccessPaymentModal
}) => {
  const dispatch = useDispatch()
  return (
    <>
      {isPaymentFormVisible && (
        <ModalContainer>
          <PaymentForm
            onClose={onPaymentFormAction}
            title={selectedProject.title}
            amount={1000}
            projectID={selectedProject.id}
            successCardPayment={() => {
              setIsSuccessPaymentModal(true)
              dispatch(onPaymentFormAction(false))
            }}
          />
        </ModalContainer>
      )}
      {isSuccessPaymentModal && (
        <ModalContainer>
          <SuccessForm
            setIsSuccessPaymentModal={setIsSuccessPaymentModal}
            projectName={selectedProject.title}
            projectID={selectedProject.id}
          />
        </ModalContainer>
      )}
    </>
  )
}

export default PaymentFormContainer
