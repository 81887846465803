import BecomePartnerPage from '../pages/BecomePartner/BecomePartner.page'
import NewsPage from '../pages/News/News.page'
import { getNews } from '../pages/News/News.actions'
import { NEWS_COUNT } from '../pages/News/News.constants'
import NewsArticlePage from '../pages/NewsArticle/NewsArticle.page'
import { getNewsArticle } from '../pages/NewsArticle/NewsArticle.actions'
import IndexPage from '../pages/Index/Index.page'
import { getActiveProjects, getCompletedProjects, getRealisationProjects } from '../pages/Index/Index.actions'
import PartnersPage from '../pages/Partners/Partners.page'
import { getPartners } from '../pages/Partners/Partners.actions'
import { PARTNERS_PER_PAGE } from '../pages/Partners/Partners.constants'
import SignupComplete from '../pages/SignupComplete/SignupComplete'
import QuestionsPage from '../pages/Questions'
import ChangeMail from '../pages/ChangeMail/ChangeMail.page'
import SchoolsPage from '../pages/Schools/Schools.page'
import { getSchoolsAction } from '../pages/Schools/Schools.actions'
import { REVIEWS_COUNT, SCHOOLS_PER_PAGE } from '../pages/Schools/Schools.constants'
import SchoolPage from '../pages/School/School.page'
import { getSchoolInfo, getSchoolProjects, getSchoolReviewAction } from '../pages/School/School.actions'
import SchoolProjectsPage from '../pages/SchoolProjects/SchoolProjects.page'
import { getSchoolProjectsAction } from '../pages/SchoolProjects/SchoolProjects.action'
import { SCHOOL_PROJECTS_PER_PAGE } from '../pages/SchoolProjects/SchoolsProjects.constants'
import CreateProjectPage from '../pages/CreateProject/CreateProject.page'
import ProjectPage from '../pages/Project/Project.page'
import { getProjectAction, getProjectCommentsAction } from '../pages/Project/Project.actions'
import SearchProjectsPage from '../pages/SearchProjects/SearchProjects.page'
import { getProjectList } from '../pages/SearchProjects/SearchProject.actions'
import { PROJECTS_PER_PAGE, SORT_OPTIONS } from '../pages/SearchProjects/SearchProject.constants'
import ProfilePage from '../pages/Profile/Profile.page'
import ProjectInfoPage from '../pages/ProjectInfo'
import DocumentsPage from '../pages/Documents/Documents.page'
import ExpertAdvice from '../pages/ExpertAdvice/ExpertAdvice.page'
import { getExpertsAction } from '../pages/ExpertAdvice/ExpertAdvice.actions'
import { EXPERTS_PER_PAGE } from '../pages/ExpertAdvice/ExpertAdvice.constants'
import NotFound from '../pages/NotFound/NotFound.page'
import AboutPage from '../pages/About/About.page'
import PlatformILSPage from '../pages/PlatformILS/PlatformILS.page'
import AnswerPage from '../pages/Answer'

const routesList = [
  {
    path: '/partner-info',
    component: BecomePartnerPage
  },
  {
    path: '/platform',
    component: AboutPage
  },
  {
    path: '/about',
    component: PlatformILSPage
  },
  {
    path: '/news',
    component: NewsPage,
    loadData: (match, dispatch) => dispatch(getNews(NEWS_COUNT)),
    exact: true
  },
  {
    path: '/news/:id',
    component: NewsArticlePage,
    loadData: (match, dispatch) => dispatch(getNewsArticle(match.params.id)),
    exact: true
  },
  {
    path: '/',
    component: IndexPage,
    loadData: (match, dispatch) => {
      return [ dispatch(getActiveProjects(4, true)),
        dispatch(getCompletedProjects(4)),
        dispatch(getRealisationProjects(4)) ]
    },
    exact: true
  },
  // проверить
  {
    path: '/auth/vk',
    component: IndexPage,
    exact: true
  },
  // проверить
  {
    path: '/auth/fb',
    component: IndexPage,
    exact: true
  },
  {
    path: '/reset_password',
    component: IndexPage
  },
  {
    path: '/partners',
    component: PartnersPage,
    loadData: (match, dispatch) => dispatch(getPartners(PARTNERS_PER_PAGE)),
    exact: true
  },
  {
    path: '/signup-complete',
    component: SignupComplete
  },
  {
    path: '/faq',
    component: QuestionsPage,
    exact: true
  },
  {
    path: '/faq/:articleId',
    component: AnswerPage,
    exact: true
  },
  {
    path: '/email-change',
    component: ChangeMail
  },
  {
    path: '/schools',
    component: SchoolsPage,
    loadData: (match, dispatch) => dispatch(getSchoolsAction(SCHOOLS_PER_PAGE, '', true)),
    exact: true
  },
  {
    path: '/school/:id',
    component: SchoolPage,
    loadData: (match, dispatch) => {
      const id = match.params.id
      return [ dispatch(getSchoolInfo(id)),
        dispatch(getSchoolProjects(id, '3', true)),
        dispatch(getSchoolReviewAction(id, REVIEWS_COUNT)) ]
    },
    exact: true
  },
  {
    path: '/school-projects/:id',
    component: SchoolProjectsPage,
    loadData: (match, dispatch) => dispatch(getSchoolProjectsAction(match.params.id, SCHOOL_PROJECTS_PER_PAGE)),
    exact: true
  },
  {
    path: '/projects/new',
    component: CreateProjectPage,
    exact: true,
    private: true
  },
  {
    path: '/projects/:id',
    component: ProjectPage,
    loadData: (match, dispatch) => {
      return [ dispatch(getProjectAction(match.params.id)),
        dispatch(getProjectCommentsAction(match.params.id)) ]
    }
  },
  {
    path: '/projects',
    component: SearchProjectsPage,
    loadData: (match, dispatch) => dispatch(getProjectList('', PROJECTS_PER_PAGE, '', '', SORT_OPTIONS[0].queryParams)),
    exact: true
  },
  {
    path: '/project-info',
    component: ProjectInfoPage,
    exact: true
  },
  {
    path: '/profile',
    component: ProfilePage,
    exact: true,
    private: true
  },
  {
    path: '/documents',
    component: DocumentsPage,
    exact: true
  },
  {
    path: '/not-found',
    component: NotFound
  },
  {
    path: '/experts',
    component: ExpertAdvice,
    loadData: (match, dispatch) => dispatch(getExpertsAction(EXPERTS_PER_PAGE)),
    exact: true
  }
]

export default routesList
