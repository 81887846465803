import * as Yup from 'yup'

import { FORM_FIELD_ERROR } from '../../constants/Errors.constans'

export const POST_COMMENTS_VALIDATION = Yup.object()
  .shape({ text: Yup.string().trim().required(FORM_FIELD_ERROR.REQUIRED_FIELD) })

export const POST_COMMENTS_FIELDS = {
  TEXT: 'text'
}

export const POST_COMMENTS_FIELDS_INITIAL = {
  text: ''
}
